<template>
  <v-navigation-drawer
    v-model="dialog"
    fixed
    :right="$root.lang != 'ar'"
    :width="$vuetify.breakpoint.mobile ? '100vw' : '40vw'"
    class="transparent"
    :style="
      $vuetify.breakpoint.mobile
        ? 'border-radius: 0;'
        : 'border-radius: 0; border: none; padding: 10px'
    "
  >
    <!-- show list of departments or doctors, show list of doctors by default -->
    <v-card
      :style="
        $vuetify.breakpoint.mobile
          ? 'height:100vh'
          : 'height: calc(100vh - 20px); max-height: calc(100vh - 20px)'
      "
      flat
      :class="$vuetify.breakpoint.mobile ? '' : 'dborder rounded-lg'"
    >
      <!-- start showing list of doctors and their filters -->
      <div v-show="!browsebydepart">
        <!-- start doctors filter section -->
        <v-app-bar
          absolute
          flat
          hide-on-scroll
          color="rgb(238, 240, 248)"
          scroll-target="#page"
          height="100px"
          class="m-0 py-0"
          style="background-color:rgb(242, 247, 253);"
        >
          <v-text-field
            filled
            class="my-0 mx-4"
            rounded
            style="width: 100%"
            dense
            hide-details
            v-model="search"
            :label="$t('APPOINTMENTS.filters.search')"
            single-line
          >
            <template v-slot:prepend-inner>
              <v-btn color="primary" class="mx-1" small icon @click="close()"
                ><v-icon color="primary"
                  >mdi-chevron-{{
                    $root.lang === "ar" ? "right" : "left"
                  }}</v-icon
                ></v-btn
              >
            </template>
            <template v-slot:append>
              <v-menu
                content-class="customMenu"
                transition="slide-y-transition"
                v-model="filtermenu"
                :min-width="$vuetify.breakpoint.mobile ? '100vw' : '40vw'"
                :close-on-content-click="false"
                offset-x
                offset-y
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" class="mx-1" small icon
                    ><v-icon color="primary">{{
                      filtermenu ? "mdi-close" : "mdi-tune"
                    }}</v-icon></v-btn
                  >
                </template>
                <v-card
                  elevation="0"
                  style="padding:10px; background-color:transparent"
                  :style="
                    $vuetify.breakpoint.mobile ? '' : 'padding-left:40px;'
                  "
                >
                  <div
                    class="dborder rounded-lg d-flex flex-column pa-3"
                    style="background-color:rgb(242, 247, 253);"
                  >
                    <div class="text-primary text-h6 d-flex mb-3 align-center">
                      <div>{{ $t("APPOINTMENTS.TABS.filter") }}</div>
                      <v-spacer />
                      <v-btn
                        small
                        elevation="0"
                        rounded
                        @click="resetFilter()"
                        color="orange lighten-4"
                      >
                        <v-icon small color="orange darken-3"
                          >mdi-replay</v-icon
                        >
                        <span class="orange--text">{{
                          $t("APPOINTMENTS.filters.reset")
                        }}</span>
                      </v-btn>
                    </div>
                    <v-autocomplete
                      v-model="departmentauto"
                      clearable
                      single-line
                      hide-details
                      dense
                      filled
                      rounded
                      :items="departments"
                      :filter="$util.departmentFilter"
                      class="mb-2"
                      :label="$t('APPOINTMENTS.filters.depart')"
                    >
                      <template v-slot:selection="data">
                        <v-avatar
                          style="
                        min-width: 18px !important;
                        min-height: 18px !important;
                      "
                          height="18px"
                          width="18px"
                          max-height="18px"
                          max-width="18px"
                          color="#d2e1f2"
                          left
                        >
                          <v-img
                            contain
                            height="10"
                            v-if="data.item.avatar"
                            :src="
                              $root.asseturl + data.item.avatar.private_hash
                            "
                          ></v-img>
                          <div
                            v-else
                            style="text-transform: uppercase; width: 100%"
                            class="primary--text font-size-h4"
                          >
                            {{ data.item.name.charAt(0) }}
                          </div>
                        </v-avatar>
                        <span class="px-1" style="font-size:1rem">{{
                          $util.getTranslation(data.item).name
                        }}</span>
                      </template>
                      <template v-slot:item="data">
                        <v-avatar
                          style="
                        min-width: 18px !important;
                        min-height: 18px !important;
                      "
                          class="pushx"
                          height="18px"
                          width="18px"
                          max-height="18px"
                          max-width="18px"
                          color="#d2e1f2"
                          left
                        >
                          <v-img
                            contain
                            height="10"
                            v-if="data.item.avatar"
                            :src="
                              $root.asseturl + data.item.avatar.private_hash
                            "
                          ></v-img>
                          <div
                            v-else
                            style="text-transform: uppercase; width: 100%"
                            class="primary--text"
                          >
                            {{ data.item.name.charAt(0) }}
                          </div>
                        </v-avatar>
                        {{ $util.getTranslation(data.item).name }}
                      </template>
                    </v-autocomplete>
                    <v-divider class="my-2" />
                    <div class="">
                      <span class="primary--text font-weight-medium">{{
                        $t("APPOINTMENTS.filters.lang")
                      }}</span>
                      <v-chip-group column v-model="filters.lang">
                        <v-chip
                          small
                          color="#d2e1f2"
                          class="primary--text"
                          active-class="white--text yellow darken-3"
                          filter
                          v-for="item in $util.getLanguages()"
                          :key="item.id"
                        >
                          <v-avatar
                            style="min-width: 18px !important; min-height: 18px !important"
                            height="18px"
                            width="18px"
                            max-height="18px"
                            max-width="18px"
                            color="white"
                            left
                          >
                            <v-img
                              contain
                              height="7"
                              :src="$root.asseturl + item.icon"
                            ></v-img>
                          </v-avatar>
                          {{ item.name }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                    <v-divider class="my-2" />
                    <div class=" d-flex align-center">
                      <div class="primary--text font-weight-medium">
                        {{ $t("APPOINTMENTS.filters.free") }}
                      </div>
                      <v-switch
                        class="my-0 py-0 mx-2"
                        v-model="filters.onlyfree"
                        inset
                        dense
                        hide-details
                      ></v-switch>
                    </div>
                    <v-divider class="my-2" />
                    <div>
                      <span class="primary--text font-weight-medium">{{
                        $t("APPOINTMENTS.filters.sort")
                      }}</span>
                      <v-chip-group column v-model="filters.sortby">
                        <v-chip
                          small
                          color="#d2e1f2"
                          class="primary--text"
                          active-class="white--text yellow darken-3"
                          filter
                          v-for="item in sortoptions"
                          :key="item.id"
                        >
                          {{ item.name }}
                        </v-chip>
                      </v-chip-group>
                    </div>
                    <div class="mt-2" style="font-size: 1.1rem;">
                      <span
                        v-html="
                          $t('APPOINTMENTS.filters.results', {
                            number: filteredDocs.length
                          })
                        "
                        >{{
                      }}</span>
                    </div>
                  </div>
                </v-card>
              </v-menu>
            </template>
          </v-text-field>
          <v-chip-group v-model="filters.department">
            <v-chip
              small
              color="#d2e1f2"
              class="primary--text"
              active-class="white--text yellow darken-3"
              filter
              v-for="item in departments"
              :key="item.id"
            >
              <v-avatar
                style="min-width: 18px !important; min-height: 18px !important"
                height="18px"
                width="18px"
                max-height="18px"
                max-width="18px"
                color="white"
                left
              >
                <v-img
                  contain
                  height="10"
                  v-if="item.avatar"
                  :src="$root.asseturl + item.avatar.private_hash"
                ></v-img>
                <div
                  v-else
                  style="text-transform: uppercase; width: 100%"
                  class="primary--text"
                >
                  {{ item.name.charAt(0) }}
                </div>
              </v-avatar>
              {{ $util.getTranslation(item).name }}
            </v-chip>
          </v-chip-group>
        </v-app-bar>
        <!-- end doctors filter section -->
        <!-- start doctors list section [hide the switch button when scrolling down, show it when scrolling up] -->
        <v-sheet
          id="page"
          v-scroll.self="onScroll"
          class="overflow-y-auto transparent hidescroll px-4"
          style="padding-top: 116px"
          :height="
            $vuetify.breakpoint.mobile ? 'calc(100vh)' : 'calc(100vh - 30px)'
          "
        >
          <v-list class="my-0 py-0" v-if="filteredDocs.length > 0">
            <v-list-item
              class="dborder mb-2 rounded-lg flex-column"
              style="min-height:unset"
              v-for="doctor in sort()"
              :key="doctor.id"
              @click="selectDoc(doctor)"
            >
              <div class="d-flex" style="width:100%">
                <v-list-item-avatar size="47" color="primary">
                  <v-img
                    v-if="doctor.doctor_id.avatar != null"
                    :src="$util.asseturl + doctor.doctor_id.avatar.private_hash"
                  ></v-img>
                  <div
                    v-else
                    style="text-transform: uppercase; width: 100%"
                    class="white--text font-size-h4"
                  >
                    {{ doctor.doctor_id.first_name.charAt(0) }}
                  </div>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-capitalize">
                    {{ doctor.doctor_id.first_name.toLowerCase() }}
                    {{ doctor.doctor_id.last_name.toLowerCase() }}
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="mt-1"
                    v-if="!doctor.isFree && $util.isPaymentEnabled()"
                  >
                    {{
                      $t("APPOINTMENTS.add.startingfrom", {
                        price:
                          parseInt(doctor.local_clinic) === 0
                            ? doctor.local_video
                            : parseInt(doctor.local_video) === 0
                            ? doctor.local_clinic
                            : doctor.local_clinic >= doctor.local_video
                            ? doctor.local_video
                            : doctor.local_clinic,
                        currency: $util.getLocalCurrency()
                      })
                    }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle class="mt-1" v-else>
                    {{ $t("APPOINTMENTS.add.free") }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    v-if="doctor.languages_spoken.length > 0"
                    class="mt-1"
                  >
                    <div style="display: flex; align-items: center">
                      {{ $t("APPOINTMENTS.add.speaks") }}
                      <div
                        v-for="lang in doctor.languages_spoken"
                        :key="lang.id"
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-avatar
                              class="mx-1 rounded light-blue lighten-5"
                              v-bind="attrs"
                              tile
                              v-on="on"
                              size="20"
                              ><v-img
                                :src="
                                  `${$util.asseturl}${lang.languages_id.flag.private_hash}`
                                "
                                width="7"
                                height="7"
                                contain
                            /></v-avatar>
                          </template>
                          <span>{{ lang.languages_id.name }} </span>
                        </v-tooltip>
                      </div>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </div>
              <v-expand-transition>
                <div
                  v-show="doctor.id === selectedDoc.id"
                  style="width:100%"
                  class=" mb-3"
                  :class="$vuetify.breakpoint.mobile ? '' : 'px-16'"
                >
                  <v-divider class="ma-0 pa-0" />
                  <div class="primary--text font-weight-medium mt-1">
                    {{ $t("APPOINTMENTS.add.about") }}
                  </div>
                  <div>{{ doctor.bio }}</div>
                  <v-row class="mb-0 pb-0">
                    <v-col class="mb-0 pb-0">
                      <router-link
                        :to="'/d/' + doctor.doctor_id.external_id"
                        v-slot="{ href, navigate }"
                      >
                        <v-btn
                          :href="href"
                          @click="navigate"
                          elevation="0"
                          small
                          rounded
                          class="white--text"
                          block
                          color="green"
                          >{{ $t("APPOINTMENTS.add.viewprofile") }}</v-btn
                        >
                      </router-link>
                    </v-col>
                    <v-col class="mb-0 pb-0">
                      <v-btn
                        @click="book(doctor)"
                        elevation="0"
                        small
                        rounded
                        block
                        color="primary"
                        >{{ $t("APPOINTMENTS.add.book") }}</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </v-expand-transition>
            </v-list-item>
          </v-list>
          <div
            class="text-muted text-center mt-10"
            style="font-size:1.3rem"
            v-else
          >
            {{ $t("APPOINTMENTS.filters.notfound") }}
          </div>
        </v-sheet>
        <!-- end doctors list section -->
      </div>
      <!-- end showing list of doctors and their filters -->
      <!-- start showing list of departments -->
      <div v-show="browsebydepart">
        <v-app-bar
          flat
          color="rgb(238, 240, 248)"
          class="d-flex justify-start align-center"
          style="background-color:rgb(242, 247, 253);"
        >
          <v-toolbar-title class="primary--text">
            <v-btn color="primary" class="pushx" small icon @click="close()"
              ><v-icon color="primary"
                >mdi-chevron-{{
                  $root.lang === "ar" ? "right" : "left"
                }}</v-icon
              ></v-btn
            >
            {{ $t("APPOINTMENTS.filters.selectdepart") }}
          </v-toolbar-title>
        </v-app-bar>
        <v-sheet
          id="page"
          v-scroll.self="onScroll"
          class="overflow-y-auto transparent hidescroll px-4"
          :height="'calc(100vh - 60px)'"
        >
          <v-row class="px-3 mt-3">
            <v-col
              class="pa-1"
              v-for="(item, i) in departments"
              :key="item.id"
              cols="12"
            >
              <v-list-item
                class="dborder rounded"
                @click="
                  filters.department = i;
                  browsebydepart = false;
                "
              >
                <v-list-item-avatar size="40" color="rgb(242, 247, 253)" left>
                  <v-img
                    contain
                    height="20"
                    v-if="item.avatar"
                    :src="$root.asseturl + item.avatar.private_hash"
                  ></v-img>
                  <div
                    v-else
                    style="text-transform: uppercase; width: 100%"
                    class="primary--text"
                  >
                    {{ item.name.charAt(0) }}
                  </div>
                </v-list-item-avatar>
                <v-list-item-content
                  ><v-list-item-title>
                    {{ $util.getTranslation(item).name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      $t("APPOINTMENTS.add.noofdoctrs", {
                        number: filteredDocs.filter(d => {
                          return d.department.id === item.id;
                        }).length
                      })
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-col>
          </v-row>
        </v-sheet>
      </div>
      <!-- end showing list of departments -->
      <!-- start switch between departments and doctors list -->
      <v-fade-transition>
        <v-btn
          v-show="showcontrol"
          style="position: absolute; bottom: 10px; margin-right:5%; margin-left:5%"
          class="mt-2"
          width="90%"
          rounded
          @click="browsebydepart = !browsebydepart"
          color="green"
          dark
          elevation="0"
          >{{
            browsebydepart
              ? $t("APPOINTMENTS.filters.browsebydoctor")
              : $t("APPOINTMENTS.filters.browsebydepart")
          }}
        </v-btn>
      </v-fade-transition>
      <!-- end switch between departments and doctors list -->
    </v-card>
    <!-- async load the booking dialog -->
    <BookAppointment @closeparent="closeandemit" ref="book" />
  </v-navigation-drawer>
</template>
<script>
export default {
  name: "NewAppointment",
  components: {
    BookAppointment: () => import("./Book.vue")
  },
  data() {
    return {
      isLive: process.env.NODE_ENV,
      dialog: false,
      search: "",
      doctors: [],
      browsebydepart: false,
      filtermenu: false,
      showcontrol: true,
      offsetTop: 0,
      departmentauto: null,
      sortoptions: [
        { id: 1, name: this.$t("APPOINTMENTS.filters.sortopt.exp") },
        { id: 2, name: this.$t("APPOINTMENTS.filters.sortopt.aff") },
        { id: 3, name: this.$t("APPOINTMENTS.filters.sortopt.sen") }
      ],
      filters: {
        department: undefined,
        onlyfree: false,
        lang: undefined,
        sortby: ""
      },
      selectedDoc: {}
    };
  },
  computed: {
    departments() {
      return this.$department.data.departments.data.filter(department => {
        return department.doctors.length > 0;
      });
    },
    filterByDepartment() {
      return this.doctors.filter(doc => {
        return (
          this.filters.department === undefined ||
          doc.department.id === this.departments[this.filters.department].id
        );
      });
    },
    filterByLanguage() {
      return this.filterByDepartment.filter(doc => {
        return (
          this.filters.lang === undefined ||
          doc.languages_spoken.find(l => {
            return (
              l.languages_id.code ===
              this.$util.getLanguages()[this.filters.lang].code
            );
          }) !== undefined
        );
      });
    },
    filterByFree() {
      return this.filterByLanguage.filter(doc => {
        return !this.filters.onlyfree || doc.isFree;
      });
    },
    filterByName() {
      return this.filterByFree.filter(doc => {
        return (
          this.search === "" ||
          `${doc.doctor_id.first_name.toLowerCase()} ${doc.doctor_id.last_name.toLowerCase()}`.includes(
            this.search.toLowerCase()
          )
        );
      });
    },
    filteredDocs() {
      return this.filterByName;
    }
  },
  watch: {
    departmentauto(updated) {
      if (updated)
        this.filters.department = this.departments.findIndex(item => {
          return item.id === updated.id;
        });
      else this.filters.department = undefined;
    },
    "filters.department"(updated) {
      console.log("updated filters department", updated);
      if (!updated) {
        this.departmentauto = null;
      } else this.departmentauto = this.departments[updated];
    },
    offsetTop(news, olds) {
      if (news <= 0) {
        this.showcontrol = true;
      } else if (olds < news) this.showcontrol = false;
      else this.showcontrol = true;
    }
  },
  beforeMount() {},
  mounted() {
    this.setDoctors();
  },
  methods: {
    selectDoc(doc) {
      if (doc.id === this.selectedDoc.id) {
        this.selectedDoc = {};
        return;
      }
      this.selectedDoc = doc;
    },
    resetFilter() {
      this.filters = {
        department: undefined,
        onlyfree: false,
        lang: undefined,
        sortby: ""
      };
      this.departmentauto = null;
      this.search = "";
      this.filtermenu = false;
    },
    sort() {
      switch (this.filters.sortby) {
        case 0:
          return this.filteredDocs.sort((a, b) => {
            if (parseFloat(a.local_video) < parseFloat(b.local_video)) return 1;
            else if (parseFloat(a.local_video) > parseFloat(b.local_video))
              return -1;
            else return 0;
          });
        case 1:
          return this.filteredDocs.sort((a, b) => {
            if (parseFloat(a.local_video) > parseFloat(b.local_video)) return 1;
            else if (parseFloat(a.local_video) < parseFloat(b.local_video))
              return -1;
            else return 0;
          });
        case 2:
          return this.filteredDocs.sort((a, b) => {
            if (a.practice_started > b.practice_started) return 1;
            else if (a.practice_started < b.practice_started) return -1;
            else return 0;
          });
        default:
          return this.filteredDocs.sort((a, b) => {
            if (a.created_on > b.created_on) return 1;
            else if (a.created_on < b.created_on) return -1;
            else return 0;
          });
      }
    },
    async setDoctors() {
      // await this.$doctor.updateLocalPrice(true);
      let tempdoctors = await this.$doctor.getAllDoctors(true);
      if (process.env.NODE_ENV === "production")
        this.doctors = tempdoctors.filter(d => {
          return !d.test;
        });
      else this.doctors = tempdoctors;
      console.log("doctors", this.doctors);
    },
    getFlag(id) {
      for (var i = 0; i < this.$util.data.langs.length; i++) {
        if (this.$util.data.langs[i].id == id)
          return (
            this.$root.assetsBaseURL +
            this.$util.data.langs[i].flag.data.asset_url
          );
      }
    },
    closeandemit(e) {
      this.$emit("updateupcoming", e);
      this.close();
    },
    close() {
      this.dialog = false;
      this.$emit("didclose");
    },
    open() {
      this.dialog = true;
    },
    onScroll(e) {
      this.filtermenu = false;
      this.offsetTop = e.target.scrollTop;
    },
    book(doctor) {
      console.log("book", doctor);
      this.$refs.book.open(doctor);
    }
  }
};
</script>
<style scoped>
.theme--light >>> .v-navigation-drawer__border {
  background-color: transparent !important;
}
.theme--light >>> .v-menu__content {
  border-radius: 0px !important;
  box-shadow: transparent !important;
  background-color: transparent !important;
}
.theme--light >>> .v-toolbar__content {
  padding: 16px;
  flex-direction: column;
}
.theme--light >>> .v-input__append-inner {
  margin: 0px;
  margin-top: 0px !important;
  align-self: center;
}
.theme--light >>> .v-input__prepend-inner {
  margin: 0px;
  margin-top: 0px !important;
  align-self: center;
}
.theme--light >>> .v-text-field--rounded > .v-input__control > .v-input__slot {
  padding: 0px 7px 0px 7px !important;
}
</style>
